.adminLanding-container {
  padding-right: 0;
  padding-left: 0;
  max-width: 1420px
}

.footer-navbar-text {
  height: 100%;
  width: 100%;
  color: #FFFFFF;
  font-family: "Myriad Pro Bold";
  font-size: 16px;
  letter-spacing: 0;
  text-align: center;
  a {
    color: #FFFFFF;
  }
  .row-height {
    margin-top: 10px;
    height: 55%!important;
  }
  img {
    width: 55px;
  }
}

#adminLandingPage.position-sticky {
  width: 260px;
  background-color: #E10210;
  min-height: 100vh;
  height: 100%;
  .positioin-fixed {
    width: 100%;
    position: absolute;
    bottom: 35px;
    left: 0;
  }
  .list-group-item {
    height: 143px;
    position: relative;
    display: block;
    padding: .5rem 1.5rem;

    background-color: #E10210;
    border: none!important;

    .navbar-text {
      height: 100%;
      width: 100%;
      color: #FFFFFF;
      font-family: "Myriad Pro Bold";
      font-size: 16px;
      letter-spacing: 0;
      text-align: center;
      a {
        color: #FFFFFF;
      }
      .row-height {
        margin-top: 10px;
        height: 55%!important;
      }
      img {
        width: 55px;
      }
    }
  }
  .list-group-item-action:active {
    color: #4f4f4f;
    background-color: #eee;
  }
  .list-group-item.active {
    background-color: #BE000C;
    color: #fff;
    border-left-color: #fff!important;
    border-left-style: solid!important;
    border-left-width: 5px!important;
    border-radius: 0!important;
  }
}

.danfoss-logo {
  height: 143px;
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {

}

// Small devices (landscape phones, less than 768px)
@media (min-width: 576px) and (max-width: 767.98px) {

}

// Medium devices (tablets, less than 992px)
@media (min-width: 768px) and (max-width: 991.98px) {
  #adminLandingPage.position-sticky {
    width: 100%;
  }
}

// Large devices (desktops, less than 1200px)
@media (min-width: 992px) and (max-width: 1199.98px) {

}

// X-Large devices (large desktops, less than 1400px)
@media (min-width: 1200px) and (max-width: 1399.98px) {  }
