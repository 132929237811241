.social-icon {
  height: 40px;
  width: 40px;
}

.lomar-logo {
  width:150px;
}

.footer-logo-text-title {
  color: #000;
  font-size: 16px;
  text-align: center;
  font-family: "Myriad Pro Bold";
}

.footer-logo-text-normal {
  color: #000;
  font-size: 16px;
  text-align: center;
  font-family: "Myriad Pro Bold";
  font-style: italic;
}

.footer-normal-text {
  a {
    color: #FFFFFF!important;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
  }
  a:hover {
    color: #969696;
  }
}
.footer-title-text {
  a {
    height: 16px;
    color: #FFFFFF!important;
    font-size: 18px;
    font-family: "Myriad Pro Bold";
    letter-spacing: 0;
    line-height: 24px;
    display: inline-block;
  }
  a:hover {
    color: #969696;
  }
}
.follow-our-global-ch {
  height: 24px;
  width: 180px;
  color: #303030;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.footer-navbar {
  background-color: #303030;
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.8rem;
    padding-left: 0rem;
    display: block;
    color: #FFFFFF;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    text-decoration: none;
    transition: none;
  }
  a:hover {
    color: #969696!important;
  }

}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .lomar-logo {
    width:120px;
  }
}

// Small devices (landscape phones, less than 768px)
@media (min-width: 576px) and (max-width: 767.98px) {
  .lomar-logo {
    width:120px;
  }
}

// Medium devices (tablets, less than 992px)
@media (min-width: 768px) and (max-width: 991.98px) {
  .lomar-logo {
    width:200px;
  }
}

// Large devices (desktops, less than 1200px)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .lomar-logo {
    width:200px;
  }
}

// X-Large devices (large desktops, less than 1400px)
@media (min-width: 1200px) and (max-width: 1399.98px) {  }
