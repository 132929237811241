.landing-text {
  max-width: 300px;
  font-size: 16px;
  text-align: right;
  color: #fff;
}
.footer-component {
  width: 100%;
}

.link-frame {
  //height: 265px;
  padding: 20px 0 20px 0;
  width: 100%;
  background-color: #EAEAEA;
}

.link-inner-frame {
  min-height: 121px;
  padding: 35px 35px 35px 35px;
  background-color: #FFFFFF;
  a {
    height: 24px;
    padding-left: 10px;
    display:inline-block;
    color: #303030;
    font-family: "Myriad Pro";
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.06px;
    line-height: 24px;
  }
  img {
    margin-top: -5px;
    width: 24px;
    height: 24px;
  }
}

.half-a-border-on-right {
  border: 1px solid #686868!important;
  position: relative;
}
.table {
  td {
    padding: 1rem 1.4rem !important;
  }
  th {
    padding: .3rem 1.4rem !important;
  }
  tr {
    border-color: #b2b2b2;
  }
}
/*. half-a-border-on-right:after {
  padding:0;margin:0;display:block;
  content: "";
  width:1px;
  height:50%;/
  background-color:#979797;
  position: absolute;
  right:0;
  top:12px;
}
*/
.tooltip {
  z-index: 3000;
  font-family: "Myriad Pro";
}

.viewcard {
  width: 98%;
  height: 475px;
  margin-bottom: 15px;
  margin-top: 15px;
  .card-body {
    padding: 1.2rem 1.2rem;
  }

  .bg-image{
    max-height: 238px;
  }
  .title-text {
    color: #303030;
    font-family: "Myriad Pro Bold";
    font-size: 20px;
    letter-spacing: 0;
    line-height: 28px;
  }
  .detail-text {
    color: #686868;
    font-family: "Myriad Pro";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
  }
}
.viewCard-link{
  width: 100%;
  a {
    width: 100%;
    display: inline-flex;
  }
}

.sidenav {
  background-color: #E10210;
}

.sidenav-mobile-visible {
  display: block;
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  table {
    table-layout: fixed;
    width: 100%;
    display: table;
    font-size: 60%;
  }
  .table > :not(caption) > * > * {
    padding: .4rem .4rem;
  }
  .wrapper .star .product-img-big {
    width: 200px;
  }
  .sidenav {
    width: 100%;
    top:52px!important;
  }
  .sidenav.sidenav-absolute {
    position: fixed!important;
    height: calc(100% - 52px);
  }
  .landing-text {
    display: none!important;
  }
}

// Small devices (landscape phones, less than 768px)
@media (min-width: 576px) and (max-width: 767.98px) {
  .wrapper .star .product-img-big {
    width: 430px;
  }
  .sidenav {
    width: 100%;
    top:52px!important;
  }
  .sidenav.sidenav-absolute {
    position: fixed!important;
    height: calc(100% - 52px);
  }
  .landing-text {
    display: none!important;
  }
}

// Medium devices (tablets, less than 992px)
@media (min-width: 768px) and (max-width: 991.98px) {
  .sidenav {
    width: 100%;
    top:72px!important;
  }
  .sidenav.sidenav-absolute {
    position: fixed!important;
    height: calc(100% - 72px);
  }

  .landing-text {
    display: none!important;
  }
}

// Large devices (desktops, less than 1200px)
@media (min-width: 992px) and (max-width: 1199.98px) {
  th {
    padding: .5rem .5rem !important;
  }
  td {
    padding: .5rem .5rem !important;
  }
}

// X-Large devices (large desktops, less than 1400px)
@media (min-width: 1200px) and (max-width: 1399.98px) {  }
