.top-location {
	box-shadow: 0 2px 15px 5px rgba(0,0,0,.07),0 10px 20px 5px rgba(0,0,0,.04);
	border-radius: 0;
	background-size: 60% 100%;
	background-repeat: no-repeat;
	background-position: top right;
  height: 420px;
  width: 100%;
	.top-title-text {
		font-size: 43px;
		line-height: 48px;
		color: #303030;
		font-family: "Myriad Pro Bold";
	}
	.top-description-text {
		color: #686868;
		font-size: 16px;
		line-height: 24px;
	}
	a {
		.top-link-btn {
			height: 72px;
			width: 80%;
			background-color: #e2000f;
			color: #fff;
			border-radius: 4px;
			border-color: #e2000f;
			cursor: pointer;
			font-size: 18px;
			font-weight: 600;
			letter-spacing: .03em;
			&:hover{
				background-color: #b5000c;
				border-color: #b5000c;
				color: #fff;
			}


		}
	}
}

.middle-left-location {
	box-shadow: 0 2px 15px 5px rgba(0,0,0,.07),0 10px 20px 5px rgba(0,0,0,.04);
	border-radius: 0;
	background-size: 100% 80%;
	background-repeat: no-repeat;
	background-position: top center;
  height: 525px;
  width: 100%;
	cursor: pointer;
	font-family: "Myriad Pro Bold";
	font-size: 24px;
	line-height: 36px;
	letter-spacing: .03em;
	color: #686868;
	&:hover{
		color: #e2000f;
	}
}

.middle-right-location {
	box-shadow: 0 2px 15px 5px rgba(0,0,0,.07),0 10px 20px 5px rgba(0,0,0,.04);
	border-radius: 0;
	background-size: 100% 80%;
	background-repeat: no-repeat;
	background-position: top center;
  height: 525px;
  width: 100%;
	cursor: pointer;
	font-family: "Myriad Pro Bold";
	font-size: 24px;
	line-height: 36px;
	letter-spacing: .03em;
	color: #686868;
	&:hover{
		color: #e2000f;
	}
}

.bottom-carusel {
	//box-shadow: 0 2px 15px 5px rgba(0,0,0,.07),0 10px 20px 5px rgba(0,0,0,.04);
	.bottom-first-card{
		box-shadow: none;
		border-radius: 0;
		padding: 30px;
		width: 406px;
		height: 240px;
		position: relative;
		top: -90px;
		right: -585px;
		.bottom-title-text {
			font-size: 30px;
			line-height: 30px;
			color: #303030;
			font-family: "Myriad Pro Bold";
			text-align: left;
		}
		.bottom-description-text {
			color: #686868;
			font-size: 16px;
			line-height: 24px;
			text-align: left;
		}
		a {
			.bottom-link-btn {
				height: 72px;
				width: 80%;
				background-color: #e2000f;
				color: #fff;
				border-radius: 4px;
				border-color: #e2000f;
				cursor: pointer;
				font-size: 18px;
				font-weight: 600;
				letter-spacing: .03em;
				&:hover{
					background-color: #b5000c;
					border-color: #b5000c;
					color: #fff;
				}


			}
		}
	}
	.bottom-second-card{
		box-shadow: none;
		border-radius: 0;
		padding: 30px;
		width: 406px;
		height: 400px;
		position: relative;
		top: 17px;
		right: -585px;
		margin-top: 20px;
		.bottom-title-text {
			font-size: 30px;
			line-height: 35px;
			color: #303030;
			font-family: "Myriad Pro Bold";
			text-align: left;
		}
		.bottom-description-text {
			color: #686868;
			font-size: 16px;
			line-height: 25px;
			text-align: left;
			width: 95%;
		}
		a {
			.bottom-link-btn {
				position: absolute;
				bottom: -30px;
				height: 72px;
				width: 80%;
				background-color: #e2000f;
				color: #fff;
				border-radius: 4px;
				border-color: #e2000f;
				cursor: pointer;
				font-size: 18px;
				font-weight: 600;
				letter-spacing: .03em;
				//margin-top:30px;
				&:hover{
					background-color: #b5000c;
					border-color: #b5000c;
					color: #fff;
				}


			}
		}
	}
	.carousel {
		height: 530px;
		.carousel-caption {
		  color: #fff;
		}
		.carousel-inner {
		  height: 100%;
			.carousel-item {
				height: 85%!important;
				img {
					height: 100%;
				}
			}
		}
		.carousel-control-prev {
		   position: inherit;
			 color: #000;
			 opacity: 0.7;
			 top: 478.5px;
			 left: 45.5%;
			 width: 3%;
		}
		.carousel-control-next {
		   position: inherit;
			 color: #000;
			 opacity: 0.7;
			 top: 446.5px;
			 left: 51.5%;
			 width: 3%;
		}
		.carousel-indicators {
			width: 5%;
			margin: 0;
			left: 47.5%;
			bottom: 13px;
		}
		.carousel-indicators  [data-mdb-target] {
			border-radius: 50%;
			background-color: #000;
			width: 15px;
			height: 15px;
			opacity: 0.3;
		}
		.carousel-indicators .active {
		  background-color: #e2000f;
			opacity: 1;
		}
	}
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
	.top-location {
		margin-left: 9px;
		margin-top: 15px;
		height: 400px;
		background-size: 100% 40%;
		background-repeat: no-repeat;
		background-position: top center;
		width:95%;
		.top-title-text {
			margin-top: 130px;
			font-size: 22px;
			line-height: 25px;
		}
		.top-description-text {
			margin-top: -10px;
			font-size: 12px;
			line-height: 20px;
		}
		a {
			.top-link-btn {
				margin-top: -30px;
				height: 40px;
				width: 100%;
				font-size: 11px;
			}
		}
	}

	.middle-left-location {
		height: 300px;
		font-size: 14px;
		line-height: 20px;
		margin-left: 25px;
	}

	.middle-right-location {
		height: 300px;
		font-size: 14px;
		line-height: 20px;
	  margin-left: 0px;
		margin-right: 25px;
	}

	.bottom-carusel {
		.bottom-first-card{
			width: 215px;
			height: 180px;
			top: 0px;
			right: -84px;
			padding: 10px;
			.bottom-title-text {
				font-size: 18px;
				line-height: 18px;
			}
			.bottom-description-text {
				margin-top:-10px;
				font-size: 12px;
				line-height: 20px;
			}
			a {
				.bottom-link-btn {
					height: 40px;
					width: 90%;
					margin-top:-20px;
					font-size: 12px;
				}
			}
		}
		.bottom-second-card{
			width: 215px;
			height: 180px;
			top: 0px;
			right: -84px;
			padding: 10px;
			.bottom-title-text {
				font-size: 16px;
				line-height: 16px;
			}
			.bottom-description-text {
				margin-top:-10px;
				font-size: 10px;
				line-height: 15px;
			}
			a {
				.bottom-link-btn {
					height: 40px;
					width: 90%;
					margin-top:-20px;
					font-size: 12px;
				}
			}
		}
		.carousel {
			height: 285px;
			.carousel-caption {
				display: block !important;
			}
			.carousel-inner .carousel-item {
			  height: 80% !important;
			}
			.carousel-control-prev {
				 top: 241.5px;
				 left: 42.5%;
			}
			.carousel-control-next {
				 top: 209.5px;
				 left: 54.5%;
			}
			.carousel-control-next-icon::after {
				font-size: 1.2rem;
			}
			.carousel-control-prev-icon::after {
				font-size: 1.2rem;
			}
			.carousel-indicators [data-mdb-target] {
				width: 11px;
				height: 11px;
			}
			.carousel-indicators {
			  width: 9%;
			  margin: 0;
			  left: 45.5%;
			  bottom: 13px;
			}
		}
	}
}

// Small devices (landscape phones, less than 768px)
@media (min-width: 576px) and (max-width: 767.98px) {
	.top-location {
		margin-top: 15px;
		height: 285px;
		.top-title-text {
			margin-top: -20px;
			font-size: 22px;
			line-height: 25px;
		}
		.top-description-text {
			margin-top: -10px;
			font-size: 12px;
			line-height: 20px;
		}
		a {
			.top-link-btn {
				margin-top: -30px;
				height: 40px;
				width: 100%;
				font-size: 11px;
			}
		}
	}

	.middle-left-location {
		height: 300px;
	  font-size: 14px;
		line-height: 20px;
	}

	.middle-right-location {
		height: 300px;
	  font-size: 14px;
		line-height: 20px;
	}

	.bottom-carusel {
		.bottom-first-card{
			width: 303px;
			height: 273px;
			top: 29px;
			right: -279px;
			.bottom-title-text {
				font-size: 24px;
				line-height: 28px;
			}
			.bottom-description-text {
				font-size: 12px;
				line-height: 20px;
			}
			a {
				.bottom-link-btn {
					height: 45px;
					width: 90%;
					margin-top:-20px;
					font-size: 14px;
				}
			}
		}
		.bottom-second-card{
			width: 303px;
			height: 273px;
			top: 29px;
			right: -279px;
			.bottom-title-text {
				margin-top:-15px;
				font-size: 20px;
				line-height: 28px;
			}
			.bottom-description-text {
				margin-top:-15px;
				font-size: 12px;
				line-height: 20px;
			}
			a {
				.bottom-link-btn {
					height: 45px;
					width: 90%;
					margin-top:-10px;
					font-size: 14px;
				}
			}
		}
		.carousel {
			height: 350px;
			.carousel-caption {
				display: block !important;
			}
			.carousel-control-prev {
			   top: 305.5px;
				 left: 44.5%;
			}
			.carousel-control-next {
			   top: 273.5px;
				 left: 52.5%;
			}
			.carousel-control-next-icon::after {
			  font-size: 1.2rem;
			}
			.carousel-control-prev-icon::after {
			  font-size: 1.2rem;
			}
			.carousel-indicators [data-mdb-target] {
			  width: 11px;
			  height: 11px;
			}
		}
	}
}

// Medium devices (tablets, less than 992px)
@media (min-width: 768px) and (max-width: 991.98px) {
	.top-location {
		margin-top: 35px;
		height: 325px;
		.top-title-text {
			font-size: 25px;
			line-height: 28px;
		}
		.top-description-text {
			font-size: 12px;
			line-height: 20px;
		}
		a {
			.top-link-btn {
				margin-top: -30px;
				height: 50px;
				width: 100%;
				font-size: 12px;
			}
		}
	}

	.middle-left-location {
		height: 345px;
	  font-size: 16px;
		line-height: 25px;
	}

	.middle-right-location {
		height: 345px;
	  font-size: 16px;
		line-height: 25px;
	}

	.bottom-carusel {
		.bottom-first-card{
			width: 335px;
			height: 325px;
			top: 0px;
			right: -300px;
			.bottom-title-text {
				font-size: 28px;
				line-height: 32px;
			}
			.bottom-description-text {
				font-size: 14px;
				line-height: 23px;
			}
			a {
				.bottom-link-btn {
					height: 55px;
					width: 90%;
					margin-top:-20px;
					font-size: 14px;
				}
			}
		}
		.bottom-second-card{
			width: 335px;
			height: 325px;
			top: 0px;
			right: -300px;
			.bottom-title-text {
				font-size: 28px;
				line-height: 32px;
			}
			.bottom-description-text {
				font-size: 14px;
				line-height: 23px;
			}
			a {
				.bottom-link-btn {
					height: 55px;
					width: 90%;
					margin-top:-20px;
					font-size: 14px;
				}
			}
		}
		.carousel {
			height: 450px;
			.carousel-control-prev {
			   top: 398.5px;
				 left: 44.5%;
			}
			.carousel-control-next {
			   top: 366.5px;
				 left: 52.5%;
			}
		}
	}
}

// Large devices (desktops, less than 1200px)
@media (min-width: 992px) and (max-width: 1199.98px) {
	.top-location {
		height: 395px;
		.top-title-text {
			font-size: 30px;
			line-height: 35px;
		}
		.top-description-text {
			font-size: 14px;
			line-height: 23px;
		}
		a {
			.top-link-btn {
				height: 60px;
				width: 100%;
				font-size: 14px;
			}
		}
	}

	.middle-left-location {
		height: 440px;
	  font-size: 20px;
		line-height: 30px;
	}

	.middle-right-location {
		height: 440px;
	  font-size: 20px;
		line-height: 30px;
	}

	.bottom-carusel {
		.bottom-first-card{
			top: -132px;
			right: -364px;
		}
		.bottom-second-card{
			width: 365px;
			height: 365px;
			top: -20px;
			right: -385px;
			.bottom-title-text {
				font-size: 30px;
				line-height: 35px;
			}
			.bottom-description-text {
				font-size: 14px;
				line-height: 23px;
			}
			a {
				.bottom-link-btn {
					height: 55px;
					width: 80%;
					margin-top:10px;
				}
			}
		}
	}
}

// X-Large devices (large desktops, less than 1400px)
@media (min-width: 1200px) and (max-width: 1399.98px) {  }
