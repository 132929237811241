
.produsts-card {
  height: 308px;
  max-width: 357px;
  background-color: #FFFFFF;
  border-radius: 5px;
	margin-top: 20px;
	margin-bottom: 0px;
  box-shadow: none;

  .card-body {
    flex: 1 1 auto;
    padding: .7rem 0 .7rem 0;
  }
  .card-title {
    margin-bottom: 0;
  }
  h5 {
    color: #303030;
    font-family: "Myriad Pro Bold";
    font-size: 27px;
    letter-spacing: 0;
    line-height: 28px;
  }
  .card-img, .card-img-top {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
	&:hover {
    //transition: 0.1s;
		max-width: 381px;
    height: 318px;
    margin-left: -11.77px;
    background-color: #F4F4F4;
		margin: 8px 12px 0 -12px;
		border: 2px solid #E10210;
    box-shadow: 0 0 25px 0 rgba(0,0,0,0.2);
    padding: 10px 10px 0 10px;
    .dropdown-icon {
      position: absolute;
      top: 20px;
      right: 25px;
    }
	}
  .dropdown-icon {
    position: absolute;
    top: 10px;
    right: 15px;
    .btn-primary {
      background-color: transparent!important;
      box-shadow: 0 0 0 0 rgba(0,0,0,0.3)!important;
      padding: 0;
    }
    img {
      height: auto;
      width: 55px;
    }
    .dropdown-toggle::after {
      display: inline-block;
      margin-left: .255em;
      vertical-align: .255em;
      content: "";
      border-top: none!important;
      border-right: none!important;
      border-bottom: none!important;
      border-left: none!important;
    }
    .btn-primary:hover, .btn-primary:focus, .btn-primary.focus {
      background-color: transparent;
      color: #FFFFFF;
      box-shadow: 0 0 0 0 #FFFFFF;
      border: none;
    }
  }
  .dropdown-menu {
    position: absolute!important;
    top: 2px !important;
    left: -73px !important;
    height: auto;
    width: 200px;
    padding-bottom: 20px;
    background-color: #F4F4F4;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.2);
    border-radius: 4px;
    img {
      width: 14px;
      height: auto;
      margin: -1px 15px 0 15px;
    }
    span {
      color: #686868;
      font-family: "Myriad Pro";
      font-size: 14px;
      letter-spacing: 0;
      line-height: 16px;
    }
  }
  .dropdown-item {
    display: inline-flex;
  }
  .popover-arrow {
    width: 0;
    height: 0;
    top: -15.5px !important;
    left: 85px !important;
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent #F4F4F4  transparent;
    margin-top: 0;
    border-style: solid;
    position: absolute;
    margin: 8px;
    margin-bottom: 0;
    color: transparent;
  }
}

.title-text {
  color: #303030;
  font-family: "Myriad Pro Bold";
  font-size: 28px;
  letter-spacing: 0;
  line-height: 40px;
  margin-bottom: 0!important
}
.detail-text-search {
  color: rgba(0,0,0,.55);
  font-family: "Myriad Pro";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
}

.url-text {
  color: #A0A0A0;
  font-family: "Myriad Pro";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  a {
    color: #A0A0A0;
    font-family: "Myriad Pro";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
  }
}

.col-btn {
  text-align: end !important;
  padding-left:0;
}

.addNew-card{
  height: 238px;
  width: 357px;
  background-color: #E10210;
  img {
    height: 102px;
    width: 102px;
  }
  p {
    height: 58px;
    width: 357px;
    color: #FFFFFF;
    font-family: "Myriad Pro Bold";
    font-size: 19px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
  }
}
.addNew-section{
  height: 107px;
  width: 100%;
  background-color: #E10210;
  img {
    height: 46px;
    width: 46px;
  }
  p {
    margin-bottom: 0!important;
    width: 357px;
    color: #FFFFFF;
    font-family: "Myriad Pro Bold";
    font-size: 19px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
  }
  .btn {
    background-color: transparent!important;
    margin: 0;
    padding:0;
    box-shadow: none!important;
    text-transform: initial;
  }
}

.quetion-card{
  height: 75%;
  width: 100%;
  background-color: #E7DADA;
  img {
    //height: 73.5px;
    width: 112.5px;
  }
}

.quetion-card-products{
  height: 238px;
  width: 100%;
  background-color: #E7DADA;
  img {
    //height: 73.5px;
    width: 112.5px;
  }
}

.quetion-card-products-search{
  min-height: 147px;
  width: 100%;
  background-color: #E7DADA;
  img {
    //height: 60.5px!important;
    width: 112.5px!important;
  }
}


.add-col {
  margin-top: 20px;
  margin-left:0;
  .btn {
    background-color: transparent!important;
    margin: 0;
    padding:0;
    box-shadow: 0 0 0 0 #FFFFFF;
    text-transform: initial;
  }
}

.transparent-btn {
  background-color: transparent!important;
  padding: 0;
  box-shadow: 0 0 0 0 rgba(0,0,0,0.2)!important;
}
.padding-col {
  padding-right: 0!important;
}
.margin-col {
  padding-left: 0;
  margin-left: -20px;
  margin-right: 20px;
}

.items-table {
  .table-responsive {
    width: 100%!important;
    box-shadow:  0 6px 8px 0 rgba(16, 27, 79, 0.2)!important;
    border-radius: 5px;
  }
  .table-header {
    height: 50px!important;
    background-color: #F3F0F0!important;
    color: #303030;
    font-family: "Myriad Pro Bold";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    border-bottom: 1px solid rgba(225,2,16,0.5);
  }
  .table {
    margin-bottom: 0;


  }
  .table > tr {
    border-top: 1px solid rgba(225,2,16,0.5);
  }
  .table-footer {
    width: 100%;
    height: 30px;
    background-color: #E10210!important;
    margin: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: .5rem 1.5rem .5rem 1.5rem;
    text-transform: initial;
    color: #FFFFFF;
    font-family: "Myriad Pro";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
    box-shadow:  0 6px 8px 0 rgba(16, 27, 79, 0.2)!important;
    border: none!important;
  }
  .table > caption {
    padding-bottom: 0!important;
    padding-top: 0!important;
    border: none;
  }
  .title-bold {
    font-family: "Myriad Pro Bold";
  }
  .form-switch .form-check-input {
    background-image: none;
    border-width: 0;
    border-radius: .4375rem;
    width: 1.3rem;
    height: .6rem;
    background-color: rgba(0,0,0,.25);
    margin-top: .3em;
    margin-right: 8px;
  }
  .form-switch .form-check-input:checked[type="checkbox"]::after {
    width: 1rem;
    height: 1rem;
    background-color: #16CC00!important;
    margin-top: -3px;
    margin-left: 0.4rem;
    box-shadow: none!important;
  }
  .form-switch .form-check-input::after {
    width: 1rem;
    height: 1rem;
    background-color: #E10210!important;
    margin-top: -3px;
    box-shadow: none!important;
  }
  .form-check-input[type="checkbox"]:checked {
    background-color: #f3f0f0;
  }
  .form-check-input[type="checkbox"] {
    background-color: #f3f0f0;
  }
  .form-switch .form-check-input:checked:focus::before {
    margin-left: 1.0625rem;
    box-shadow: none;
    transform: scale(0);
    transition: box-shadow 0s,transform 0s;
  }
  .form-switch .form-check-input:focus::before {
    margin-left: 1.0625rem;
    box-shadow: none;
    transform: scale(0);
    transition: box-shadow 0s,transform 0s;
  }
}

.section-part {

  .form-outline .form-control ~.form-notch {
    display: none;
  }
  .form-outline .form-control {
    height: 30px;
    max-width: 553px;
    border-radius: 2px;
    background-color: #F4F4F4;
  }
  #software_section .form-outline{
    .form-control {
      height: 40px;
      max-width: 553px;
      border-radius: 2px;
      background-color: #F4F4F4;
      //border: solid 1px;
    }
    .select-arrow {
      top: 8px;
    }
  }

  #description.form-control {
    height: 192px!important;
  }
  .form-outline .form-control ~ .form-label {
    padding-left: 10px;
  }
  .form-control {
    color: #686868!important;
    font-family: "Myriad Pro"!important;
    font-size: 16px!important;
    letter-spacing: 0!important;
    line-height: 24px!important;
  }

}

.drag-area {
  .padding-margin-setup{
    width: auto;
    margin-right: 15px;
    margin-bottom: 15px;
    padding: 0;
  }
  .add-new-btn {
    position: relative;
    left: 0;
    top: 0;
    height: 107px;
    width: 161px;
    background-color: transparent !important;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.2) !important;
  }
  .addNew-card{
    height: 107px;
    width: 161px;
    background-color: #E10210;
    img {
      height: 46px;
      width: 46px;
    }
    p {
      height: 26px;
      color: #FFFFFF;
      font-family: "Myriad Pro";
      font-size: 11px;
      letter-spacing: 0;
      line-height: 16px;
      text-align: center;
      padding-top: 5px!important;
    }
  }
}
.photo-area {
  height: 107px;
  width: auto;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
  margin: 0;
  display: flex;
  justify-content: center;
  background-color: #e10210;
  text-align: center;
  cursor: pointer;
  img {
    height: 107px;
    width: auto;
  }

}
.photo-area-client {
  //height: 107px;
  //width: auto;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
  background-image: url('../../media/icons/DISCONTINUED.svg');
  background-repeat: no-repeat;
	background-position: center center;
  margin: 0;
  display: flex;
  justify-content: center;
  background-color: rgba(225,2,16,0.3);
  text-align: center;
  border-radius: .25rem;
  cursor: pointer;
  img {
    height: auto;
    width: 217px;
  }

}
.discontinued{
  background-image: url('../../media/icons/DISCONTINUED.svg');
  background-repeat: no-repeat;
	background-position: center center;
  z-index: 3000;
}
.delete-btn {
  position: absolute;
  padding: 0;
  margin: 0px;
  height: 107px;
  width: auto;

  .btn {
    background-color: #fff !important;
    box-shadow: none !important;
    border-radius: 50%;
    width:46px;
    height:46px;
    padding: 0;
    margin: 0;
    img {
      height: 22px;
      width: 15px;
    }
  }
}

.add-title-text {
  height: 37px;
  color: #303030;
  font-family: "Myriad Pro Bold";
  font-size: 27px;
  letter-spacing: 0;
  line-height: 37px;
  margin: 10px 0 15px 0;
}
.add-normal-text {
  //height: 32px;
  color: #303030;
  font-family: "Myriad Pro";
  font-size: 14px;
  letter-spacing: 0;
  //line-height: 32px;
}
.add-comment-text {
  height: 21px;
  color: #686868;
  font-family: "Myriad Pro";
  font-size: 11px;
  letter-spacing: 0;
  line-height: 16px;
}
.opacity-setup {
  position: absolute;
  height: 107px;
  //width: 161px;
  opacity: 0.5;
  background-color: #E10210;
}


// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .quetion-card-products{
    height: 212px;
    width: 100%;
    background-color: #E7DADA;
    img {
      height: 73.5px;
      width: 73.5px;
    }
  }
}

// Small devices (landscape phones, less than 768px)
@media (min-width: 576px) and (max-width: 767.98px) {
  .quetion-card-products{
    height: 212px;
    width: 100%;
    background-color: #E7DADA;
    img {
      height: 73.5px;
      width: 73.5px;
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (min-width: 768px) and (max-width: 991.98px) {
  .quetion-card-products{
    height: 160px;
    width: 100%;
    background-color: #E7DADA;
    img {
      height: 73.5px;
      width: 73.5px;
    }
  }
  .produsts-card {
    height: 248px;
    max-width: 242px;
    background-color: #FFFFFF;
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 0px;
    box-shadow: none;
    h5 {
      color: #303030;
      font-family: "Myriad Pro Bold";
      font-size: 22px;
      letter-spacing: 0;
      line-height: 23px;
    }
    .card-img, .card-img-top {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    &:hover {
      //transition: 0.1s;
      max-width: 242px;
      height: 250px;
      margin-left: -11.77px;
      background-color: #F4F4F4;
      margin: 8px 12px 0 -12px;
      border: 2px solid #E10210;
      box-shadow: 0 0 25px 0 rgba(0,0,0,0.2);
      padding: 10px 10px 0 10px;
      .dropdown-icon {
        position: absolute;
        top: 20px;
        right: 25px;
      }
    }
  }
  .addNew-card {
    height: 155px;
    width: 232px;
    background-color: #E10210;
    img {
      height: 75px;
      width: 75px;
    }
  }
  .quetion-card{
    height: 62%;
    width: 100%;
    background-color: #E7DADA;
    img {
      height: 73.5px;
      width: 73.5px;
    }

  }
  .margin-col {
    padding-left: 0;
    margin-left: -30px;
    margin-right: 0;
  }
  .title-text {
    font-size: 20px;
    line-height: 25px;
  }
  .url-text {
    font-size: 12px;
    line-height: 24px;
    a {
      font-size: 12px;
      line-height: 24px;
    }
  }
}

// Large devices (desktops, less than 1200px)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .quetion-card-products{
    height: 204px;
    width: 100%;
    background-color: #E7DADA;
    img {
      height: 73.5px;
      width: 73.5px;
    }
  }
  .produsts-card {
    h5 {
      color: #303030;
      font-family: "Myriad Pro Bold";
      font-size: 22px;
      letter-spacing: 0;
      line-height: 23px;
    }
    .card-img, .card-img-top {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  	&:hover {
      //transition: 0.1s;
  		max-width: 381px;
      height: 250px;
      margin-left: -11.77px;
      background-color: #F4F4F4;
  		margin: 8px 12px 0 -12px;
  		border: 2px solid #E10210;
      box-shadow: 0 0 25px 0 rgba(0,0,0,0.2);
      padding: 10px 10px 0 10px;
      .dropdown-icon {
        position: absolute;
        top: 20px;
        right: 25px;
      }
  	}
  }
  .addNew-card {
    height: 162px;
    width: 242px;
    background-color: #E10210;
    img {
      height: 75px;
      width: 75px;
    }
  }
  .quetion-card{
    height: 51%;
    width: 100%;
    background-color: #E7DADA;
    img {
      height: 73.5px;
      width: 73.5px;
    }
  }
  .margin-col {
    padding-left: 0;
    margin-left: -60px;
    margin-right: 0;
  }
  .title-text {
    font-size: 24px;
    line-height: 30px;
  }
  .url-text {
    font-size: 12px;
    line-height: 24px;
    a {
      font-size: 12px;
      line-height: 24px;
    }
  }
}

// X-Large devices (large desktops, less than 1400px)
@media (min-width: 1200px) and (max-width: 1399.98px) {  }
